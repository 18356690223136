<template>
    <el-main>
      <el-tabs v-model="activeName">
        <el-tab-pane label="代销会员合同" name="1">
          <el-form class="el-form-search" label-width="140px">
            <el-form-item label="申请店铺名称：">
              <el-input v-model="searchForm1.shop_name" size="small" placeholder="请输入店铺名称"></el-input>
            </el-form-item>
            <el-form-item label="申请会员姓名：">
              <el-input v-model="searchForm1.name" size="small" placeholder="请输入会员姓名"></el-input>
            </el-form-item>
            <el-form-item label="申请会员电话：">
              <el-input v-model="searchForm1.mobile" size="small" placeholder="请输入联系电话"></el-input>
            </el-form-item>
            <el-form-item label="授权店铺名称：">
              <el-input v-model="searchForm1.apply_shop_name" size="small" placeholder="请输入店铺名称"></el-input>
            </el-form-item>
            <el-form-item label="授权会员姓名：">
              <el-input v-model="searchForm1.apply_name" size="small" placeholder="请输入会员姓名"></el-input>
            </el-form-item>
            <el-form-item label="授权会员电话：">
              <el-input v-model="searchForm1.apply_mobile" size="small" placeholder="请输入联系电话"></el-input>
            </el-form-item>
            <el-form-item label="申请时间：">
                <el-date-picker
                v-model="searchForm1.time"
                type="daterange"
                range-separator="~"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="timestamp">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="处理时间：">
                <el-date-picker
                v-model="searchForm1.deal_time"
                type="daterange"
                range-separator="~"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="timestamp">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="申请状态：">
              <el-select v-model="searchForm1.status" placeholder="请选择">
                <el-option label="全部" :value="0"></el-option>
                <el-option label="已通过" :value="1"></el-option>
                <el-option label="已驳回" :value="-1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label=" " label-width="20px">
              <el-button size="small" @click="search1" type="primary">搜索</el-button>
              <el-button size="small" @click="searchClear1" type="text">清除搜索条件</el-button>
            </el-form-item>
          </el-form>
          <div class="shop-info" style="margin-bottom:10px">
            <el-image :src="shop_logo"></el-image>
            <div>{{ shop_name }}</div>
          </div>
          <el-table :data="lists" :header-cell-style="{ 'background-color': '#F8F9FA' }">
            <template slot="empty">
              <No />
            </template>
            <el-table-column label="申请时间" align="center" width="160px">
              <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
            </el-table-column>
            <el-table-column label="申请店铺名称" align="center">
              <template v-slot="{ row }">
                <div class="shop-info">
                  <el-image :src="row.logo"></el-image>
                  <div>{{ row.shop_name }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="申请会员姓名" align="center"></el-table-column>
            <el-table-column prop="mobile" label="申请会员电话" align="center"></el-table-column>
            <el-table-column label="授权店铺名称" align="center">
              <template v-slot="{ row }">
                <div class="shop-info">
                  <el-image :src="row.apply_logo"></el-image>
                  <div>{{ row.apply_shop_name }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="apply_name" label="授权会员姓名" align="center"></el-table-column>
            <el-table-column prop="apply_mobile" label="授权会员电话" align="center"></el-table-column>
            <el-table-column prop="name" label="申请状态" align="center">
              <template v-slot="{ row }">
                <div :style="{'color':row.status==1?'#409EFF':'#F56C6C'}"> {{ row.status==1?'已通过':'已驳回'}}</div>
              </template>
            </el-table-column>
            <el-table-column label="处理时间" align="center" width="160px">
              <template v-slot="{ row }">{{ getDateformat(row.update_time) }}</template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" align="center" width="120px">
              <template v-slot="{ row }">
                <el-button type="text" v-if="row.status==1" @click="look(row.contract,true)">查看合同</el-button>
                <el-button type="text" v-else @click="look(row.reason,false)">驳回原因</el-button>
              </template>
            </el-table-column>
          </el-table>
          <Paging :total="total_number1" :page="searchForm1.page" :pageNum="searchForm1.rows" @updatePageNum="updateData1"></Paging>
        </el-tab-pane>
        <el-tab-pane label="代销平台合同" name="2">
          <el-form class="el-form-search" label-width="140px">
            <el-form-item label="申请店铺名称：">
              <el-input v-model="searchForm2.shop_name" size="small" placeholder="请输入店铺名称"></el-input>
            </el-form-item>
            <el-form-item label="申请会员姓名：">
              <el-input v-model="searchForm2.name" size="small" placeholder="请输入会员姓名"></el-input>
            </el-form-item>
            <el-form-item label="申请会员电话：">
              <el-input v-model="searchForm2.mobile" size="small" placeholder="请输入联系电话"></el-input>
            </el-form-item>
            <el-form-item label="申请时间：">
                <el-date-picker
                v-model="searchForm2.time"
                type="daterange"
                range-separator="~"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="timestamp">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="处理时间：">
                <el-date-picker
                v-model="searchForm2.deal_time"
                type="daterange"
                range-separator="~"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="timestamp">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="申请状态：">
              <el-select v-model="searchForm2.status" placeholder="请选择">
                <el-option label="全部" :value="0"></el-option>
                <el-option label="已通过" :value="1"></el-option>
                <el-option label="已驳回" :value="-1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label=" " label-width="20px">
              <el-button size="small" @click="search2" type="primary">搜索</el-button>
              <el-button size="small" @click="searchClear2" type="text">清除搜索条件</el-button>
            </el-form-item>
          </el-form>
          <div class="shop-info">
            <el-image :src="shop_logo"></el-image>
            <div>{{ shop_name }}</div>
          </div>
          <el-table :data="platformList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
            <template slot="empty">
              <No />
            </template>
            <el-table-column label="申请时间" align="center" width="160px">
              <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
            </el-table-column>
            <el-table-column label="申请店铺名称" align="center">
              <template v-slot="{ row }">
                <div class="shop-info">
                  <el-image :src="row.logo"></el-image>
                  <div>{{ row.shop_name }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="申请会员姓名" align="center"></el-table-column>
            <el-table-column prop="mobile" label="申请会员电话" align="center"></el-table-column>
            <el-table-column prop="name" label="申请状态" align="center">
              <template v-slot="{ row }">
                <div :style="{'color':row.status==0?'#E6A23C':row.status==1?'#409EFF':'#F56C6C'}"> {{ row.status==0?'审核中':row.status==1?'已通过':'已驳回'}}</div>
              </template>
            </el-table-column>
            <el-table-column label="处理时间" align="center">
              <template v-slot="{ row }">{{ getDateformat(row.update_time) }}</template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" align="center" width="120px">
              <template v-slot="{ row }">
                <el-button type="text" v-if="row.status==0">审核</el-button>
                <el-button type="text" v-else-if="row.status==1" @click="look(row.contract,true)">查看合同</el-button>
                <el-button type="text" v-else @click="look(row.reason,false)">驳回原因</el-button>
              </template>
            </el-table-column>
          </el-table>
          <Paging :total="total_number2" :page="searchForm2.page" :pageNum="searchForm2.rows" @updatePageNum="updateData2"></Paging>
        </el-tab-pane>
      </el-tabs>

      <el-dialog :title="is_hetong?'合同':'驳回原因'" :visible.sync="show" width="650px">
       <div style="min-height:400px">{{remark}}</div>
      </el-dialog>
    </el-main>
  </template>

  <script>
  import Paging from '@/components/paging';
  import { getDateformat } from '../../util/getDate';
  export default {
    components: {
      Paging,
    },
    data() {
      return {
        activeName: '1',
        shop_logo: '',
        shop_name: '',
        searchForm1: {
          page: 1,
          rows: 10,
          shop_name: '',
          name: '',
          mobile:'',
          apply_shop_name:'',
          apply_name:'',
          apply_mobile:'',
          time:'',
          deal_time:'',
          status:0
        },
        searchForm2: {
          page: 1,
          rows: 10,
          shop_name: '',
          name: '',
          mobile:'',
          time:'',
          deal_time:'',
          status:0
        },
        total_number1: 0,
        total_number2: 0,

        lists: [],
        platformList: [],
        show: false,
        remark: '',
        is_hetong:true
      };
    },
    created() {
      this.shop_logo = this.$route.query.shop_logo;
      this.shop_name = this.$route.query.shop_name;

      this.getLists();
      this.getPlatformList();
    },
    methods: {
      getDateformat,
      updateData1(val, status) {
        if (status == 0) {
          this.searchForm1.rows = val;
        } else {
          this.searchForm1.page = val;
        }
        this.getLists();
      },
      updateData2(val, status) {
        if (status == 0) {
          this.searchForm2.rows = val;
        } else {
          this.searchForm2.page = val;
        }
        this.getPlatformList();
      },
      search1() {
        this.searchForm1.page = 1;
        this.getLists();
      },
      search2() {
        this.searchForm1.page = 1;
        this.getPlatformList();
      },
      searchClear1() {
        this.searchForm1 = {
          page: 1,
          rows: 10,
          shop_name: '',
          name: '',
          mobile:'',
          apply_shop_name:'',
          apply_name:'',
          apply_mobile:'',
          time:'',
          deal_time:'',
          status:0
        };
        this.getLists();
      },
      searchClear2() {
        this.searchForm2 = {
          page: 1,
          rows: 10,
          shop_name: '',
          name: '',
          mobile:'',
          time:'',
          deal_time:'',
          status:0
        };
        this.getPlatformList();
      },
      getLists(){
        let data = {
          ...this.searchForm1,
          start_time:this.searchForm1.time?this.searchForm1.time[0]/1000:'',
          end_time:this.searchForm1.time?this.searchForm1.time[1]/1000:'',
          deal_start_time:this.searchForm1.deal_time?this.searchForm1.deal_time[0]/1000:'',
          deal_end_time:this.searchForm1.deal_time?this.searchForm1.deal_time[1]/1000:''
        }
        this.$axios
        .post(this.$api.gateway.proxy.getLists, data)
        .then(res => {
          if (res.code == 0) {
            this.lists = res.result.list;
            this.total_number1 = res.result.total;
          } else {
            this.$message.error(res.msg);
          }
        });
      },
      getPlatformList(){
        let data = {
          ...this.searchForm2,
          start_time:this.searchForm2.time?this.searchForm2.time[0]/1000:'',
          end_time:this.searchForm2.time?this.searchForm2.time[1]/1000:'',
          deal_start_time:this.searchForm2.deal_time?this.searchForm2.deal_time[0]/1000:'',
          deal_end_time:this.searchForm2.deal_time?this.searchForm2.deal_time[1]/1000:''
        }
        this.$axios
        .post(this.$api.gateway.proxy.getPlatformList, data)
        .then(res => {
          if (res.code == 0) {
            this.platformList = res.result.list;
            this.total_number2 = res.result.total;
          } else {
            this.$message.error(res.msg);
          }
        });
      },
      look(remark,is_hetong){
        this.remark = remark
        this.is_hetong = is_hetong
        this.show = true
      }
    },
  };
  </script>

  <style lang="less" scoped>
  .el-main {
    background: #fff;
    .shop-info {
      display: flex;
      align-items: center;
      .el-image {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 10px;
      }
    }
  }
  </style>
